@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,500,600|Montserrat:300,500");


:root {
  --primary: rgba(210, 105, 30, 1);
  --secondary: rgba(76, 70, 61, 1);
  --light: rgba(255, 255, 243, 1);
  --dark: rgba(31, 31, 31, 1);
  --filter: rgba(76, 70, 61, 0.698);

  --font-size-s: 15px;
  --font-size-m: 20px;
  --font-size-l: 30px;
  --font-size-xl: 50px;

  --font-family-monserrat: 'Montserrat';
  --font-family-poppins: 'Poppins';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#landing-bg {

  background-image: linear-gradient(rgba(77, 71, 61, 0.7),
      rgba(77, 71, 61, 0.7)), url('../public/assets/images/landing-bg.jpg');
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#landing-card {

  background-image: url('../public/assets/images/paper-pattern.png');
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#tn-cotizar{
  color: #FFFFF3;
}

#title {
  font-family: var(--font-family-monserrat);
  font-size: var(--font-size-l);
  letter-spacing: 0px;
  font-weight: 500;
  font-style: normal;
}


#subtitle {
  font-family: var(--font-family-monserrat);
  font-size: var(--font-size-m);
  letter-spacing: 0px;
  font-weight: 300;
  font-style: normal;
}

#price{
  font-family: var(--font-family-monserrat);
  font-size: var(--font-size-m);
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  color: var(--primary);
}

#text {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0px;
  font-weight: 300;
  font-style: normal;

}

#link{
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  color: var(--primary);
  text-decoration: none;
}

#link:hover{
  text-decoration: underline;
  color: var(--secondary);
  cursor: pointer;
}


.sidebar {
  display: flex;
  height: 100vh; 
  background-color: brown;
}

.sidebar__sidebar {
  position: relative;
  min-height: 100vh;
  width: 18%;
  margin: 0px;
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: 10px 0px 12px 2px rgba(0, 0, 0, 0.15);
  
}
#navigation-menu{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}


.sidebar__main {
  /* Take the remaining width */
  flex: 1;
  background-color: var(--light);

  /* Make it scrollable */
  overflow: auto;
}

#navigation-button{
  width: 100%;
  font-family: var(--font-family-monserrat);
  font-size: var(--font-size-m);
  font-weight: 300;
}

#table-cell{
  vertical-align: middle;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0px;
  font-weight: 300;
  font-style: normal;

}

#table-cell-actions{
  display: none;
  vertical-align: middle;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0px;
  font-weight: 300;
  font-style: normal;
}

#table-cell-actions:hover {
  display: inline-flex;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px; /* Opcional: ajusta el espaciado según tus necesidades */
}

.form-check-input:checked{
  background-color: var(--primary) !important;
  border-color:var(--primary) !important;
  }